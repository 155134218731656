@import "../../../app/styles/vars";
.chatWindow {
  display: flex;
  width: 100%;
  height: calc(100vh);
  scroll-behavior: smooth;
  @media (max-width: $breakpoint-tablet) {
    //overflow-x: hidden;
    width: 200vw;
  }
  &-left {
    display: flex;
    width: 30%;
    max-width: 300px;
    min-width: 300px;
    flex-shrink: 0;
    box-sizing: border-box;
    flex-direction: column;
    transition: width .2s linear;
    @media (max-width: $breakpoint-tablet) {
      width: 100vw;
      max-width: 100vw;
    }
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-button {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0);
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    :hover::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .2);
    }
    ::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, .2);
    }
    ::-webkit-scrollbar-track {
      background: rgba(255,255,255,0);
      border: 0px none rgba(255,255,255,0);
      border-radius: 12px;
    }
    ::-webkit-scrollbar-track:hover {
      background: rgba(0, 0, 0, .1);
    }
    ::-webkit-scrollbar-track:active {
      background: rgba(0, 0, 0, .1);
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    &:hover {
      scrollbar-color: rgba(0, 0, 0, .2) rgba(0, 0, 0, 0);
    }
    scrollbar-width: 4px;
    background: #FFFFFF;
    &-container {
      overflow-y: auto;
      max-height: 100%;
      width: 100%;
      position: relative;
      z-index: 1;
    }
    &-navbar {
      display: flex;
      width: 100%;
      max-width: 300px;
      height: 50px;
      position: relative;
      background: #FFFFFF;
      z-index: 2;
      &-search {

        width: calc(100% - 58px);
        box-sizing: border-box;
        background: rgba(0,0,0,.07);
        border-radius: 4px;
        height: 34px;
        display: flex;
        margin-top: 7px;
        align-items: center;
        &-icon {
          width: 30px;
          height: 30px;
          background-image: url("../../../shared/assets/img/findIcon-b.png");
          background-size: 20px;
          background-repeat: no-repeat;
          opacity: .5;
          background-position: 10px 6px;
          padding-right: 14px;
        }
        &-label {
          display: flex;
          width: 100%;
          font-size: 13px;
          opacity: .6;
          padding-left: 14px;
          color: black;
          .theme-dark & {
            color: #FFFFFF;
          }
        }
        input {
          outline: none;
          width: 100%;
          border: none;
          background: none;
          height: 34px;
          padding: 0px 10px;
          box-sizing: border-box;
        }
      }
    }
    &__menuContainer {
      margin-bottom: 20px;
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 5px;

      &__element {
        font-size: 13px;
        opacity: .85;
        cursor: pointer;
        padding: 10px 7px;
        border-radius: 4px;
        font-weight: 500;
        &:hover {
          background: rgba(0,0,0,.1);
        }
        &--active {
          background: rgba(0,0,0,.05);
          color: rgba(0,0,0,.85);
          font-weight: bold;
        }
        &__home {
          width: 18px;
          height: 18px;
          background-image: url("../../../shared/assets/img/home.png");
          background-size: cover;
          display: inline-block;
          margin-right: 12px;
          top: 3px;
          position: relative;
        }
      }
    }
    &__label {
      font-size: 11px;
      opacity: .4;
      margin-bottom: 10px;
      margin-left: 14px;
      font-weight: bold;
    }
    &-menu {
      display: flex;
      width: 64px;
      height: 50px;
      cursor: pointer;
      background-image: url("../../../shared/assets/img/menu-icon.png");
      background-size: 16px;
      background-repeat: no-repeat;
      opacity: .5;
      position: relative;
      flex-shrink: 0;
      background-position: 50% 65%;
    }
    &-close {
      display: flex;
      width: 40px;
      height: 40px;
      cursor: pointer;
      background-image: url("../../../shared/assets/img/closeIcon.png");
      background-size: 16px;
      background-repeat: no-repeat;
      opacity: .5;
      position: relative;
      flex-shrink: 0;
      background-position: 50% 65%;
      .theme-dark & {
        background-image: url("../../../shared/assets/img/closeIcon-white.png");
      }
    }
  }
  &-right {
    display: flex;
    width: 100%;
    min-width: 715px;
    border-left: 1px solid rgba(0,0,0,.05);
    box-sizing: border-box;
    justify-content: center;
    @media (max-width: $breakpoint-tablet) {
      width: 100vw;
      max-width: 100vw;
      min-width: 100vw;
    }
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-button {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0);
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    :hover::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .2);
    }
    ::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, .2);
    }
    ::-webkit-scrollbar-track {
      background: rgba(255,255,255,0);
      border: 0px none rgba(255,255,255,0);
      border-radius: 12px;
    }
    ::-webkit-scrollbar-track:hover {
      background: rgba(0, 0, 0, .1);
    }
    ::-webkit-scrollbar-track:active {
      background: rgba(0, 0, 0, .1);
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    &:hover {
      scrollbar-color: rgba(0, 0, 0, .2) rgba(0, 0, 0, 0);
    }
    scrollbar-width: 4px;
    &-container {
      overflow-y: auto;
      max-height: 100%;
      width: 100%;
    }
  }

  .messageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 170px;
    padding-top: 0px;
    &-emptyChat {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      opacity: .4;
      color: rgba(0,0,0,1);
    }
    &-you {
      background-color: white;
      border-bottom: 1px solid rgba(0,0,0,.1);
    }
    &-wrapper {
      display: flex;
      width: 100%;
      overflow-y: auto;
      border-bottom: 1px solid rgba(0,0,0,.05);
      justify-content: center;
      padding-bottom: 20px;
    }
    &-message {
      display: flex;
      padding-top: 30px;
      width: 100%;
      max-width: 700px;
      font-size: 15px;
      line-height: 24px;
      color: rgba(0,0,0,.8);
      @media (max-width: $breakpoint-tablet) {
        max-width: 100vw;
        overflow-x: hidden;
        word-break: break-word;
      }
      &-avatar {
        display: flex;
        width: 36px;
        height: 36px;
        border-radius:50%;
        font-size: 14px;
        margin-right: 17px;
      }
      &-control {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      &-bodyContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        .messageBody-body {
          background: rgba(255,255,255,.07);
          font-style: italic;
          border-radius: 4px;
          margin-top: 3px;
          padding: 10px;
          padding-left: 0;
          &-link {
            color: rgba(0,0,0,.6);
            font-weight: 500;
          }
        }
        .messageBodyCredentials {
          display: flex;
          justify-content: space-between;
          width: 100%;
          &-author {
            font-weight: bold;
          }
          &-date {
            opacity: .4;
          }
        }
      }
    }
    .messageTextArea {
      position: fixed;
      bottom: 40px;
      box-sizing: border-box;
      display: flex;
      width: calc(100% - 307px);
      justify-content: center;
      min-width: 715px;
      flex-direction: column;
      align-items: center;
      @media (max-width: $breakpoint-tablet) {
        min-width: calc(100vw - 3%);
        margin-left: calc(100vw - 99%);
      }
      &-container {
        max-width: 700px;
        width: 100%;
        display: flex;
        border: 1px solid rgba(0,0,0,.1);
        background: white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        min-height: 30px;
        border-radius: 6px;
        align-items: flex-end;
        flex-direction: row;
        box-sizing: border-box;
        .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
          padding-left: 3px;
        }
      }

      &-switch {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 700px;
        label {
          font-size: 12px;
          color: rgba(0,0,0,.75);
          font-weight: bold;
          padding-top: 3px;
          &:before {
            content: '';
            width: 20px;
            height: 20px;
            position: relative;
            top: 4px;
            background-image: url("../../../shared/assets/img/icon-magic-wand.png");
            background-size: 20px;
            background-repeat: no-repeat;
            display: inline-block;
            display: none;
            opacity: .6;
          }
        }
      }
      .MuiTextField-root {
        width: 100%;
      }
      &-send {
        cursor: pointer;
        width: 40px;
        height: 55px;
        background-image: url("../../../shared/assets/img/send.png");
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center;
        opacity: .3;
        background-color: rgba(0,0,0,0);
        outline: none;
        border: none;
        margin-right: 5px;
        &:hover {
          opacity: .6;
        }
      }
      &-promptButton {
        cursor: pointer;
        width: 40px;
        height: 55px;
        background-image: url("../../../shared/assets/img/terminal-icon.png");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        opacity: .2;
        background-color: rgba(0,0,0,0);
        outline: none;
        border: none;
        &:hover {
          opacity: .6;
        }
      }
      &-prompt {
        cursor: pointer;
        width: 40px;
        height: 55px;
        background-image: url("../../../shared/assets/img/behind-icon-code.png");
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: center;
        opacity: .3;
        background-color: rgba(0,0,0,0);
        outline: none;
        border: none;
        &:hover {
          opacity: .6;
        }
      }
      fieldset {
        border: none;
        box-shadow: none;
      }
    }
  }
  .chatContactsContainer {
    width: 100%;
    padding-top: 5px;
    .chatContacts-contact {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      padding-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      box-sizing: border-box;
      font-size: 14px;
      color: rgba(0,0,0,.85);
      justify-content: space-between;
      &-badge {
        width: 20px;
        height: 20px;
        display: flex;
        border-radius: 50%;
        background: rgba(0,87,255,1);
        color: white;
        font-size: 10px;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-weight: 500;
      }
      &-container {
        display: flex;
      }
      &-selected {
        background-color: rgba(0,0,0,.05);
      }
      .messageContainer-message-avatar {
        margin-right: 10px;
        width: 36px;
        height: 36px;
      }
      &:hover {
        background: rgba(0,0,0,.05);
        transition: all linear 0.2s;
      }
      .chatContacts-member {
        font-weight: bold;
        font-size: 13px;
      }
      .chatContacts-chatName {
        font-size: 13px;
      }

    }
  }
}
.addCompaniesToChat {
  width: 100%;
  padding-left: 40px;
  padding-top: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #1a71e4;
  box-sizing: border-box;
  height: 60px;
  padding-bottom: 20px;
  padding-right: 40px;
}
.left-nav-dropdown-icon-companies {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/companies-icon.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}
.left-nav-dropdown-icon-chat {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/chat-icon.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}
.left-nav-dropdown-icon-kanban {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/kanban-icon.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}
.left-nav-dropdown-icon-home {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/home.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}

.left-nav-dropdown-icon-user {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/user-icon.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}
.left-nav-dropdown-icon-prompts {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/terminal-icon.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}
.left-nav-dropdown-icon-login {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/login-icon-blue.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: 1;
  color: #0057ff;
}
.left-nav-dropdown-icon-signup {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/signup-icon-blue.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: 1;
  color: #0057ff;
}
.left-nav-dropdown-icon-campaigns {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/megaphone-icon.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}
.left-nav-dropdown-icon-notifications {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/bell-icon.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}
.left-nav-dropdown-icon-logs {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/log-history.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}
.left-nav-dropdown-icon-settings {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/setting.png");
  background-size: cover;
  display: inline;
  margin-right: 16px;
  opacity: .6;
}
.theme-dark {
  //background: #1d1d26;
  .chatWindow {
    &-left {
      ::-webkit-scrollbar-thumb {
        background: rgba(0,0,0, 0);
        border: 0px none #000000;
      }
      :hover::-webkit-scrollbar-thumb {
        background: rgba(255,255,255, .2);
      }
      ::-webkit-scrollbar-thumb:active {
        background: rgba(255,255,255, .2);
      }
      ::-webkit-scrollbar-track {
        background: rgba(0,0,0,0);
        border: 0px none rgba(0,0,0,0);
      }
      ::-webkit-scrollbar-track:hover {
        background: rgba(255,255,255, .1);
      }
      ::-webkit-scrollbar-track:active {
        background: rgba(255,255,255, .1);
      }
      scrollbar-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
      &:hover {
        scrollbar-color: rgba(255,255,255, .2) rgba(255,255,255, 0);
      }
      scrollbar-width: 4px;
      background: #1a1a1c;
      &-container {
      }
      &-navbar {
        background: #1a1a1c;
        &-search {
          background: rgba(255,255,255,.07);
          &-icon {
            background-image: url("../../../shared/assets/img/findIcon.png");
          }
          input {
            color: rgba(255,255,255,.85);
          }
        }
      }
      &-menu {
        background-image: url("../../../shared/assets/img/menu-icon-w.png");
        &-element {
          color: white;
          opacity: 1;
          font-size: 14px;
        }
        &:hover {
          opacity: 1;
          transition: all linear .2s;
        }
      }
    }
    &-right {
      border-left: 1px solid rgba(255,255,255,.05);
      background: #1d1d26;
      ::-webkit-scrollbar-thumb {
        background: rgba(0,0,0, 0);
        border: 0px none #000000;
        border-radius: 50px;
      }
      :hover::-webkit-scrollbar-thumb {
        background: rgba(255,255,255, .2);
      }
      ::-webkit-scrollbar-thumb:active {
        background: rgba(255,255,255, .2);
      }
      ::-webkit-scrollbar-track {
        background: rgba(0,0,0,0);
        border: 0px none rgba(0,0,0,0);
        border-radius: 12px;
      }
      ::-webkit-scrollbar-track:hover {
        background: rgba(255,255,255, .1);
      }
      ::-webkit-scrollbar-track:active {
        background: rgba(255,255,255, .1);
      }
      ::-webkit-scrollbar-corner {
        background: transparent;
      }
      scrollbar-color: rgba(255,255,255, 0) rgba(255,255,255, 0);
      &:hover {
        scrollbar-color: rgba(255,255,255, .2) rgba(255,255,255, 0);
      }
      &-container {
      }
    }
    .messageContainer {
      &-emptyChat {
        color: rgba(255,255,255,1);
      }
      &-you {
        background-color: #2a2a2d;
        border-bottom: 1px solid rgba(25,255,255,.1);
      }
      &-wrapper {
        border-bottom: 1px solid rgba(255,255,255,.05);
      }
      &-message {
        color: rgba(255,255,255,.8);
        .MuiInputBase-multiline {
          border: none;
        }
        textarea {
          color: rgba(255,255,255,.8);
          border: none;
        }
        &-avatar {
        }
        &-bodyContainer {
          .messageBodyCredentials {
            &-author {
            }
            &-date {
              opacity: .4;
            }
          }
        }
      }
      .messageTextArea {
        &-container {
          border: 1px solid rgba(0,0,0,.1);
          background: #2b2c2f;
          box-shadow: 0px 0px 10px 0px rgba(0,0,0, .3);
        }
        &-switch {
          label {
            color: rgba(255,255,255,.55);
            &:before {
              content: '';
              width: 20px;
              height: 20px;
              position: relative;
              top: 4px;
              background-image: url("../../../shared/assets/img/icon-magic-wand.png");
              background-size: 20px;
              background-repeat: no-repeat;
              display: inline-block;
              display: none;
              opacity: .6;
            }
          }
        }
        .MuiTextField-root {
        }
        &-send {
          background-image: url("../../../shared/assets/img/icon-send-w.png");
          background-color: rgba(255,255,255,0);
          &:hover {
            opacity: .6;
          }
        }
        &-prompt {
          background-image: url("../../../shared/assets/img/behind-icon-code-w.png");
          opacity: .3;
          background-color: rgba(255,255,255,0);
          &:hover {
            opacity: .6;
          }
        }
        fieldset {

        }
        textarea {
          color: rgba(255,255,255,.85);
        }
      }
    }
    .chatContactsContainer {
      .chatContacts-contact {
        color: rgba(255,255,255, 0.95);
        &-selected {
          background-color: rgba(255,255,255,.05);
        }
        .messageContainer-message-avatar {
        }
        &:hover {
          background: rgba(255,255,255,.05);
        }
        .chatContacts-member {
          color: rgba(255,255,255,.95);
          font-weight: 500;
        }
        .chatContacts-chatName {
          color: rgba(255,255,255,.6);
        }
      }
    }
  }
  .left-nav-dropdown-icon-companies {
    width: 16px;
    height: 16px;
    background-image: url("../../../shared/assets/img/companies-icon-w.png");
    background-size: cover;
    display: inline;
    margin-right: 16px;
    opacity: .6;
  }
  .left-nav-dropdown-icon-chat {
    width: 16px;
    height: 16px;
    background-image: url("../../../shared/assets/img/chat-icon-w.png");
    background-size: cover;
    display: inline;
    margin-right: 16px;
    opacity: .6;
  }
  .left-nav-dropdown-icon-user {
    width: 16px;
    height: 16px;
    background-image: url("../../../shared/assets/img/user-icon-w.png");
    background-size: cover;
    display: inline;
    margin-right: 16px;
    opacity: .6;
  }
  .left-nav-dropdown-icon-campaigns {
    width: 16px;
    height: 16px;
    background-image: url("../../../shared/assets/img/megaphone-icon-w.png");
    background-size: cover;
    display: inline;
    margin-right: 16px;
    opacity: .6;
  }
  .left-nav-dropdown-icon-notifications {
    width: 16px;
    height: 16px;
    background-image: url("../../../shared/assets/img/bell-icon-w.png");
    background-size: cover;
    display: inline;
    margin-right: 16px;
    opacity: .6;
  }
  .left-nav-dropdown-icon-settings {
    width: 16px;
    height: 16px;
    background-image: url("../../../shared/assets/img/setting-icon-w.png");
    background-size: cover;
    display: inline;
    margin-right: 16px;
    opacity: .6;
  }
}