.ku {
  &__search {
    &__input {
      display: flex;
      flex-direction: row;


      width: 100%;
      padding-top: 5px;
      input {
        border: 1px solid rgba(0,0,0,.1);
        border-radius: 7px;
        outline: none;
        width: 100%;
        padding: 4px;
        margin-right: 10px;
      }
    }
  }
  &__grid {
    display: flex;
    background: #FFFFFF;
    height: 100vh;
    overflow-y: auto;
    &__col {
      display: flex;
      flex-direction: column;

    }
    &__row {
      display: block;
    }
    &__element {
      cursor: pointer;
      padding-left: 12px;
      padding-right: 12px;
      &--selected {
        background: #efefef;

      }
    }
    &__search {
      &--row {
        border-bottom: 1px solid rgba(0,0,0,.05);
      }
      &--element {
        font-size: 15px;
        padding-top: 3px;
        padding-bottom: 3px;
        .companyCard {
          padding-left: 0;
        }
        &:hover {
          .ku__grid__search {
            &__header {
              text-decoration: underline;
            }
            &__category {

              color: rgba(0,0,0,.9);
            }

          }
        }
      }
      &__header {
        font-size: 14px;
        font-weight: 500;
        font-family: "Noto Sans", sans-serif;
        color: #082f7a;
      }
      &__category {
        font-size: 12px;
        font-family: "Noto Sans", sans-serif;
        color: rgba(0,0,0,.7);
      }
    }
  }
}
.kub {
  &__search {
    &__input {
      display: flex;
      flex-direction: row;
    }
  }
  &__container {
    width: 1500px;
    max-width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
  }
  &__blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__block {
    padding: 20px;
    border: 1px solid rgba(0,0,0,.05);
    background: rgba(0,0,0,.05);
    border-radius: 12px;
    &--3grid {
      width: 29.5%;
      margin-bottom: 20px;
    }
  }
  &__card {
    &__header {
      font-size: 20px;
      font-weight: bold;
      display: inline;
    }
    &__description {
      margin-top: 20px;
      font-size: 15px;
      line-height: 25px;
      color: rgba(0,0,0,.85);
    }
    &__category {
      font-size: 14px;
      color: rgba(0,0,0,.7);
      margin-top: 10px;
    }
  }
}
.card-icon-companies {
  width: 16px;
  height: 16px;
  background-image: url("../../../shared/assets/img/companies-icon.png");
  background-size: cover;
  display: inline-block;
  margin-right: 5px;
  opacity: .6;
}