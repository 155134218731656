.loader__container {
  display: inline;
  &:before {
    content: '';
    background-size: cover;
    width: 14px;
    height: 14px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    margin-right: 4px;
  }
  &--light {
    display: inline;
    &:before {
      background-image: url("../../../shared/assets/img/tail-spin.svg");
    }
  }
  &--dark {
    display: inline;
    &:before {
      background-image: url("../../../shared/assets/img/tail-spin-dark.svg");
    }
  }
}

