.sipWidget {
  &__box {
    position: absolute;
    z-index: 999;
    display: flex;
    background: rgba(27, 21, 51, 1);
    background-color: rgba(27, 21, 51, 1);
    -webkit-backdrop-filter: blur(10px);
    overflow: hidden;
    backdrop-filter: blur(4px);
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    animation: fadeIn ease-in .5s backwards;
   &__extended {
     background: rgba(27, 21, 51, 1);
     background-color: rgba(27, 21, 51, 1);
     -webkit-backdrop-filter: blur(10px);
     backdrop-filter: blur(4px);
     box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
   }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  }
  &__audioVisualisation{
    width: 150px;
    height: 100px;
    padding: 0;
    margin: 0;
    top: 0;

    path {
      stroke: #f0d339;
      stroke-width: 7;
      stroke-linecap: round;
    }
  }
  &__timer {
    display: flex;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      opacity: .8;
    }
  }
  &__hangUp {
    //background-color: rgba(0,0,0,.1);
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-image: url("../../shared/assets/img/call-end-red.png");
    background-size: 26px;
    background-position: center;
    display: flex;
    background-repeat: no-repeat;
    margin-left: 5px;
    position: relative;
    top: 1px;
  }
  &__extend {
    background-color: rgba(255,255,255,.05);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 9px;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 5px;
    opacity: .6;
    position: relative;
    top: 5px;
    width: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__transcript {
    &__container {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      font-family: monospace;
      font-size: 13px;
      line-height: 24px;
      overflow-y: auto;
      flex-shrink: 0;
    }
  }
}