.kanban {
  &__board {
    display: flex;
    flex-direction: column;
    height: 100vh;
    &__container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
    }
    &__column {
      border: 1px solid rgba(0,0,0,.1);
      margin-right: 20px;
      width: 270px;
      flex-shrink: 0;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      border-radius: 7px;
      background: #eeeeee;
      overflow: hidden;
      &__header {
        background: rgba(0,0,0,.1);
        padding: 10px;
        font-size: 13px;
        font-weight: bold;
      }
      &:last-child {
        margin-right: 0;
      }
      &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
      &--draggingOver {
        //background: lightblue;
      }
    }
    &__card {
      padding: 10px;
      border: 1px solid rgba(0,0,0,.1);
      margin: 5px 10px;
      background: white;
      border-radius: 7px;
      &--dragging {
        //background: lightgreen;
      }
      &__person {
        display: flex;
        flex-direction: column;
        &__nameAvatar {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &__nameDate {
          display: flex;
          flex-direction: column;
        }
        &__avatar {
          div {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
          }
        }
        &__name {
          font-size: 16px;
          font-weight: bold;
        }
        &__date {
          font-size: 13px;
          font-style: italic;
          padding-top: 2px;
          color: rgba(0,0,0,.7)
        }
        &__summary {
          font-size: 14px;
          color: rgba(0,0,0,.8);
          margin-top: 10px;
          line-height: 22px;
        }
      }
    }

  }
}